import React, { Component } from "react";
import * as cookies from "../cookie.js";

import logo from './assets/svg/mol_ret_cad_molson_primary_lockup_1C_rgb_V2.svg';
import gillnhl from './assets/svg/GIII_NHL_Logo_EN.svg';
import molsoncase from './assets/img/cdn-Q2-Hky-15x355ml-ctn-fr.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import { Link } from "react-router-dom";
export default class ContactPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

    }


    render() {

        return (

          <div className="app app--contact">

            <header className="navigationMenu backgroundColorRed">

              <div className="navigationMenuContents">

              <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

              <div className="langSwitcher">
                <a href="#" className="langSwitcher__item">EN</a>
                <a href="./ContactPagefr" className="langSwitcher__item">FR</a>
              </div>

              </div>

            </header>

            <div className="contentContainer contentContainer--viewport">

              <div className="contactInfo">

                <h1 className="contactInfo__title textColorBlue">Contact Us</h1>

                <p className="contactInfo__text textColorGrey">Need help?</p>
                <p className="contactInfo__text textColorGrey">Contact customer support at</p>
                <p className="contactInfo__text textColorGrey">1-800-MOLSON1</p>

              </div>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonCanadian" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/Molson_Canadian" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsoncanadian/" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPage">Rules & Regulations</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPage">Contact Us</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}

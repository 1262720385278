import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';


import MainPage from "./pages";


import MainPagefr from "./pages/indexfr.jsx";
import TermsPage from "./pages/terms.jsx";
import TermsPagefr from "./pages/termsfr.jsx";
import ContactPage from "./pages/contact.jsx";
import ContactPagefr from "./pages/contactfr.jsx";

import NotFoundPage from "./pages/404";

class App extends Component{
    render(){
        return <Router>
                <Switch>
                   <Route exact path="/" component={MainPage} />
                <Route exact path="/indexfr" component={MainPagefr} />

                    <Route exact path="/TermsPage" component={TermsPage} />
                <Route exact path="/TermsPagefr" component={TermsPagefr} />
                    <Route exact path="/ContactPage" component={ContactPage} />
                <Route exact path="/ContactPagefr" component={ContactPagefr} />

                    <Route exact path="/404" component={NotFoundPage} />
                    <Redirect to="/404" />
               </Switch>
              </Router>

    }
}

export default App;

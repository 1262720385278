import React from "react";

import logo from './assets/svg/mol_ret_cad_molson_primary_lockup_1C_rgb_V2.svg';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';

const NotFoundPage = () => {

    return (

      <div className="app app--404">

        <header className="navigationMenu backgroundColorRed">

          <div className="navigationMenuContents">

            <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

          </div>

        </header>

        <div className="contentContainer contentContainer--viewport">

          <div className="contactInfo">

            <h1 className="contactInfo__title textColorBlue">404 Not Found</h1>

            <p className="contactInfo__text textColorGrey">Sorry, the page you're looking for doesn't exist.</p>

            <p className="contactInfo__text textColorGrey">Need help?</p>
            <p className="contactInfo__text textColorGrey">Contact customer support at</p>
            <p className="contactInfo__text textColorGrey">1-800-MOLSON1.</p>

          </div>

        </div>

        <footer className="footer backgroundColorRed">

          <div className="footerContents">

            <ul className="footerSocials">

              <li className="footerSocials__item">
                <a href="https://www.facebook.com/MolsonCanadian" target="_blank">
                  <img src={facebook}/>
                </a>
              </li>

              <li className="footerSocials__item">
                <a href="https://twitter.com/Molson_Canadian" target="_blank">
                  <img src={twitter}/>
                </a>
              </li>

              <li className="footerSocials__item">
                <a href="https://www.instagram.com/molsoncanadian/" target="_blank">
                  <img src={instagram}/>
                </a>
              </li>

            </ul>

            <ul className="footerLinks">

              <li className="footerLinks__item">
                <a href="/TermsPage">Terms & Conditions</a>
              </li>

              <li className="footerLinks__item">
                <a href="/FAQPage">FAQ</a>
              </li>

              <li className="footerLinks__item">
                <a href="/ContactPage">Contact Us</a>
              </li>

            </ul>

          </div>

        </footer>

      </div>

    );
};

export default NotFoundPage;
